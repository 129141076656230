<template>
  <div>
    <b-modal
      v-model="showModal"
      body-class="p-3"
      hide-footer
      centered
    >
      <template #modal-header>
        <b-row class="header-filter no-gutters">
          <b-col lg="6" class="ft-title">Center Point</b-col>
          <b-col class="text-right">
            <button
              type="button"
              aria-label="Close"
              class="close text-right"
              @click="hide"
            >×</button>
          </b-col>
        </b-row>
      </template>
      <InputText
        textFloat="New password"
        placeholder="Password must be 6 characters or more."
        type="password"
        name="new_password"
        isRequired
        :isShowPassword="true"
        v-model="$v.form.new_password.$model"
        :isValidate="$v.form.new_password.$error"
        :v="$v.form.new_password"
      />

      <InputText
        textFloat="Confirm password"
        placeholder="Password must be 6 characters or more."
        type="password"
        name="confirm_password"
        isRequired
        :isShowPassword="true"
        v-model="$v.form.password.$model"
        :isValidate="$v.form.password.$error"
        :v="$v.form.password"
      />
      <b-row class="mt-3">
        <b-col md="6">
          <b-button class="btn-cancel btn-width" variant="dark" @click="hide"
            >CANCEL</b-button
          >
        </b-col>
        <b-col md="6" class="text-sm-right">
          <b-button
            class="main-color btn-save btn-width"
            @click="savePasswordChange()"
          >
            Confirm
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    InputText,
  },

  data() {
    return {
      form: { new_password: "", password: "" },
      showModal: false,
    };
  },
  validations: {
    form: {
      new_password: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
      },
      password: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.form = { new_password: "", password: "" };
    },
    savePasswordChange() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$emit("submit", this.form.password);
      this.hide();
    },
  },
};
</script>

<style scoped>
::v-deep .bg-header-modal-primary {
  background-color: #ece1f5;
}
.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}
::v-deep .modal-header {
  display: block;
  background-color: var(--secondary-color);
}
.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}
.img {
  width: 100px;
}
@media (max-width: 767.98px) {
  /* .text-text {
    font-size: 15px;
  } */
}
</style>
